import React, { Component } from 'react'

import { Button, Form, Input, Select, Table } from 'antd';
import { connect } from 'react-redux';
import {fetchTPPaymentPending,CollectTPPayment} from '../../../action/thirldParty'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../components/header';
const { Option } = Select;
class TPCollectAmount extends Component {
  state = { 
    
    searchText: '',
    searchedColumn: '',
    data: [],
    drivers: '',
    rider: '',
    amount: '',
   } 
    componentDidMount = () => {
        this.getData()
    };
 
    getData = async () => {
      const res = await this.props.fetchTPPaymentPending()
      this.setState({data: res})
    }
    createOption = (label, value) => {
      return { label, value };
    };
   
  
    getColumnSearchProps =  dataIndex => ({
        filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
                });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
           
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (total_delivered,tp_collection,action) => {
        return {total_delivered,tp_collection,action};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = async () => {
    
        const res = await this.props.CollectTPPayment(this.state.amount)
        if(res === 201){
          this.setState({amount: ''})
        }
      
    }
    
    onDriverChange = async (e) => {
        const res = await this.props.hasToPay(e.split('-')[0])
        this.setState({data: res})
        this.setState({rider: e})
    }
  render() { 
    
    const columns = [
  
      {
        title: 'Total Delivered',
        dataIndex: 'total_delivered',
        key: 'total_delivered',
        width: '20%'
      },
      {
        title: 'Collection',
        dataIndex: 'tp_collection',
        key: 'tp_collection',
        width: '20%'
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '10%'
      }
    ];
    const {data, drivers, rider,amount} = this.state
    const rows = [
      data
        ? this.createData(
            data?.total_delivered
            ,
              <Input placeholder={data?.tp_collection} onChange={(e)=> {this.setState({amount: e.target.value})} }/>
            ,
            
            <Button size="small" type="primary" disabled={data?.tp_collection != amount } onClick={() => this.handleSubmit()} >Collect</Button>
            
            )
          
        : "",
    ];
    
    const options = [
      drivers
        ? drivers?.map((item) =>
            this.createOption(
              `${item.mobile_number}----` + item.name,
              `${item.user_id}--`+ item.name
            )
          )
        : null,
    ];
    return (
      <>
        
        <Header title="Collect Amount" subtitle="Parcel" />
        <br />
        
            {data
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky  />
            : 
            null}
      </>
    );
  }
}

export default connect(null,{fetchTPPaymentPending,CollectTPPayment})(TPCollectAmount);