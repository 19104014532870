import history from '../../history'
import React from 'react'


    const normalOrder = [
        {name: 'WAREHOUSE PARCELS', to: "/parcels-warehouse"},
    ]


    const transitOrder = [
        {name: 'PENDING PARCELS', to: "/parcels-transit-pending"},
        {name: 'SEND PARCELS', to: '/parcels-send'},
    ]

const ODDOrder = [
    {name: 'PENDING PARCELS', to: "/parcels-odd-pending"},
    {name: 'ASSIGN PARCELS',to: "/parcels-odd-assign"},
    {name: 'PROCESSING PARCELS', to: "/parcels-odd-processing"},
]

const thridParyOder = [
    {name: 'PROCESSING PARCELS', to: "/parcel-processing-tp"},
    {name: 'SEND PARCELS',to: "/parcel-send-tp"},
    {name: 'COLLECT AMOUNT', to: "/parcels-odd-processing"},
    {name: 'COLLECT RETURN PARCELS', to: "/parcel-return-collect-tp"},
]

const ThirdParty = () => {
    const itemClassName = "p-3 bg-white hover:text-red-600 hover:shadow-xl hover:transition-all hover:translate-x-1 font-semibold cursor-pointer rounded-sm"

    const regularDeliver = () => (
        <div className='bg-teal-400 text-center col-span-2 p-4 rounded-md'>
            <h1 className='text-2xl font-extrabold text-center'>Regular Delivery</h1>
            <div class="grid grid-cols-2 grid-flow-col gap-1 p-4 ">
                {/* ======= Normal Order ======= */}
                <div className='bg-green-300 rounded-sm' >
                    <h1 className='p-4 text-xl font-bold '>Normal Order</h1>
                    <div class="grid grid-rows-6 grid-flow-col gap-1 p-4 ">
                        {normalOrder.map((item,id) => (
                            <p className= {itemClassName} onClick={() => history.push(item?.to)}>{item.name}</p>
                        ))}
                    </div>
                </div>

         

                {/* ========== Transit Order ========= */}
                <div className='bg-indigo-500 rounded-sm' >
                <h1 className='p-4 text-xl font-bold'>Transit Order</h1>
                    <div class="grid grid-rows-6 grid-flow-col gap-1 p-4 ">
                        
                        {transitOrder.map((item,id) => (
                            <p className= {itemClassName}  onClick={() => history.push(item?.to)}>{item.name}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )

    const OnDayDelivery = () => (
        <div className='bg-cyan-500 p-4 rounded-md'>
            <h1 className='text-2xl font-extrabold text-center'>Same Day Delivery</h1>
            <div class="grid grid-rows-6 grid-flow-col gap-1 p-4">
                {ODDOrder.map((item,id) => (
                    <p className={itemClassName} onClick={() => history.push(item?.to)}>{item.name}</p>
                ))}
            </div>
        </div>
    )

    const thirdParty = () => (
        <div className='bg-lime-400 p-4 rounded-md'>
            <h1 className='text-2xl font-extrabold text-center'>Third Party</h1>
            <div class="grid grid-rows-6 grid-flow-col gap-1 p-4">
                {thridParyOder.map((item,id) => (
                    <p className={itemClassName} onClick={() => history.push(item?.to)}>{item.name}</p>
                ))}
            </div>
        </div>
    )
    return ( 
        <div class="grid grid-cols-3  gap-2 p-1 place-content-center bg-indigo-200" style={{height: '100vh'}}>
                {/* ========Regular Delivery========= */}
                {regularDeliver()}


                {thirdParty()}
        </div>
     );
}
 
export default ThirdParty;