import React, { Component } from 'react'

import { Button, Col, Form, Grid, Input, Modal, Row, Select , Table } from 'antd';
import { connect } from 'react-redux';
import {fetchSingleReturn,pickupReturnedOrder} from '../../../../action/parcel'
import {fetchDrivers} from '../../../../action/drivers'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../../components/header';
import ReactToPrint from "react-to-print";
import photo from './stickerLogo.png';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import styled from 'styled-components';
import { InvoiceContainer } from './invoice.style';
import returned from './returned.png';

const { Option } = Select;
const Watermark = styled.img  `
    position: absolute;
    width: 100px;
    /* height: 100px; */
    top: 150px;
    left: 40%;
    opacity: 0.5 ;
    /* transform: rotate(15deg); */
`;
class PrintBarCode extends Component {
  state ={
    invoice : JSON.parse(localStorage.getItem('singleParcel')),
  }
  printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        let imgWidth = 208;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        // pdf.output('dataurlnewwindow');
        pdf.save(JSON.parse(localStorage.getItem('singleParcel')).created_at.split("T")[0] + "---" + JSON.parse(localStorage.getItem('singleParcel')).order_id)  ;
      })
    ;
  }
  render() {
    const {invoice} = this.state;
    var today = new Date();
    const date = invoice.created_at.split("T")[0];
    const t = invoice.created_at.split("T")[1];
    const time = t.split(".")[0];
    const hour = (today.getHours() + 24) % 12 || 12
    const orderDate = new Date(invoice.created_at);
    return(
     <>
    
        <InvoiceContainer id="divToPrint" style={{padding: '1px'}}>
        <Watermark src={returned} />
          <Row gutter={2} style={{margin: '0px',padding: '0px'}} >
            <Col  xs={8} style={{borderRight: '1px solid #000'}}>
              <img src={photo} />
            </Col>
            <Col  xs={8} style={{borderRight: '1px solid #000',paddingLeft: '5px'}}>
              <p style={{fontSize: '10px'}} >{invoice.shop_name}</p>
              <p style={{fontSize: '10px'}} >{invoice.shop_mobile}</p>
              <p style={{fontSize: '10px'}} >Invoice : {invoice.merchant_order_id}</p>
              <p style={{fontSize: '10px'}} >{date + ' ' + time}</p>
            </Col>
            <Col  xs={8}>
              <p style={{fontSize: '10px',textAlign:'center'}} >Amount to be collect</p>
              <h3 style={{textAlign:'center'}}>{invoice.amount_to_collect} &#2547;</h3>
            </Col>
          </Row>
            
            {/* ===========Info== */}
           
              <Row gutter={0} style={{margin: '0px'}}>
                  <Col  xs={24}><h5>Customer Details</h5></Col>
                    <Col  xs={4} style={{padding: '0px'}}><p >Name</p></Col><Col  xs={20}><p style={{fontWeight:'bold'}}>: {invoice.customer_name}</p></Col>
                    <Col  xs={4} style={{padding: '0px'}}><p>Phone</p></Col><Col  xs={20}><p style={{fontWeight:'bold'}}>: {invoice.customer_phone}</p></Col>
                    <Col  xs={4} style={{padding: '0px'}}><p>Address</p></Col><Col  xs={20}><p style={{fontWeight:'bold'}}>: {invoice.customer_address}</p></Col>
                    <br/>
                  <Col  xs={24}><h5>Product Details</h5></Col>
                    <Col  xs={4} style={{padding: '0px'}}><p>Description</p></Col><Col  xs={20}><p>: {invoice.product_description}</p></Col>
                    <Col  xs={4} style={{padding: '0px'}}><p>Items</p></Col><Col item xs={20}> <p>: {invoice.item}</p></Col>
              </Row>
               
            <Row gutter={0} style={{display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
              <Col xs={8}>
                <QRCode value={invoice.order_id} size={55}/>
              </Col>
              <Col  xs={12}>
                <Barcode  value={invoice.order_id} width='1' height="30" text={invoice.order_id} fontSize="10px" fontWeight='bold'/>
              </Col>
            </Row>
            
            <Row container spacing={1} style={{ fontSize:'10px', fontWeight:'bold', margin: '0px'}}>
              <Col item xs={12}> {localStorage.getItem('user_id')} </Col>
              <Col item xs={12} style={{textAlign:'right'}}>{today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()} </Col>
            </Row>
        </InvoiceContainer>
        <button onClick={this.printDocument} style={{cursor: 'pointer', backgroundColor: '#06aa4a', padding: '12px 185px', textDecoration: 'none', color: '#fff'}} >Download</button>

  </>
    )
  }
}


// ============================================
class PickupReturnParcels extends Component {
  state = { 
    searchText: '',
    searchedColumn: '',
    data: null,
    weight: '',
    r_no: '',
    drivers: '',
    rider: '',
    order_id: '',
    visible: false,
   } 
    componentDidMount = () => {
        this.getDrivers()
    };
    getDrivers = async () => {
      const res = await this.props.fetchDrivers()
      this.setState({drivers: res})
    }
   
    
    createOption = (label, value) => {
      return { label, value };
    };

    getColumnSearchProps =  dataIndex => ({
        filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
                });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
            {/* <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                });
                }}
            >
                Filter
            </Button>
            </Space> */}
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (order_id,merchant_order_id,store_info,customer_info,charge_info,weight,r_no,order_time,action) => {
        return {order_id,merchant_order_id,store_info,customer_info,charge_info,weight,r_no, order_time,action};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = async (data) => {
      const {weight,rider,r_no} = this.state
      if(!rider){
        alert('Select Driver')
      }
      else if(!r_no){
        alert('Insert Receipt no')
      }
      else{
        localStorage.removeItem('invoice')
        localStorage.removeItem('barcode')
        const res = await this.props.pickupParcel(data,weight,rider,r_no)
        if(res === 200){
            this.setState({rider: '',weight: '', r_no: ''})
            this.getData()
        }
      }
   
    }
    onDriverChange = async (e) => {
      this.setState({rider: e})
    }
    onFinish = async ({rider,order_id}) => {
      this.setState({order_id:order_id })
      const res = await this.props.fetchSingleReturn(order_id,rider)
      if(res === 200 ){
        this.setState({visible: true})
      }
    };

    handlePickup = async () => {
      const res = await this.props.pickupReturnedOrder(this.state.order_id, this.state.rider)
      if(res===201){
        this.setState({rider: '', order_id: ''})
      }
    }
    
    handleOk = () => {
      this.setState({visible: false})
    };
  
    handleCancel = () => {
      this.setState({visible: false})
    };
  render() { 
 
    const {data, drivers} = this.state
  
    const options = [
      drivers
        ? drivers?.map((item) =>
            this.createOption(
              `${item.mobile_number}----` + item.name,
              item.user_id
            )
          )
        : null,
    ];
    
    return (
      <>
        
        <Header title="Pickup Parcels" subtitle="Return Parcel" name="return_order" />
        <br />
        <Form 
          onFinish={this.onFinish}
          >
          <Row> 
            <Col xs={10}>
              <Form.Item
                label="Select Pickup Rider"
                name="rider"
                rules={[
                  {
                    required: true,
                    message: 'Please select a rider',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a rider"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {options[0]? 
                  options[0]?.map((item,id) => (
                    <Option value={item?.value}>{item?.label}</Option>
                  ))
                :null}
                </Select>
                </Form.Item>
            </Col>
            <Col xs={10}>
            <Form.Item
                label="Order ID"
                name="order_id"
                rules={[
                  {
                    required: true,
                    message: 'Please enter order id',
                  },
                ]}
              >
              <Input />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Button htmlType='submit'>Search</Button>
            </Col>
          </Row>
        </Form>
       
        {localStorage.getItem('singleParcel') ?
          <Modal title="Invoice" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} width="auto">
            <div style={{width:'35%',margin: 'auto', }} >
              <PrintBarCode ref={(el) => (this.PrintBarCode = el)} />
              <span onClick={() => { this.setState({receipt_no:''})}}>
                <ReactToPrint
                trigger={() => <a href="#" style={{position: 'relative', top: '20px',backgroundColor: '#3498db', padding: '12px 170px', textDecoration: 'none', color: '#fff'}} >Print this out!</a>}
                content={() => this.PrintBarCode}
              />
              </span>
              <br/>
              <br/>
              <br/>
              <br/>
              <a href="#" onClick={() => this.handlePickup()} style={{backgroundColor: '#e74c3c', padding: '12px 200px', textDecoration: 'none', color: '#fff'}}>Collect</a>
            </div>
            </Modal>
        : null}
        <br />
           

      </>
    );
  }
}

export default connect(null,{fetchSingleReturn,fetchDrivers,pickupReturnedOrder})(PickupReturnParcels);