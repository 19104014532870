import React, { Component } from 'react'

import { Button, Form, Input, Select, Table } from 'antd';
import { connect } from 'react-redux';
import {fetchDrivers,hasToPay,receiveCollection} from '../../action/drivers'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../components/header';
const { Option } = Select;
class CollectAmount extends Component {
  state = { 
    
    searchText: '',
    searchedColumn: '',
    data: [],
    drivers: '',
    rider: '',
    amount: '',
   } 
    componentDidMount = () => {
        this.getDrivers()
    };
 
    getDrivers = async () => {
      const res = await this.props.fetchDrivers()
      this.setState({drivers: res})
    }
    createOption = (label, value) => {
      return { label, value };
    };
   
  
    getColumnSearchProps =  dataIndex => ({
        filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
                });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
            {/* <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                });
                }}
            >
                Filter
            </Button>
            </Space> */}
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (driver,hasToPay,received,action) => {
        return {driver,hasToPay,received,action};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = async () => {
      if(this.state.rider === ''){
        alert('Please select a rider')
      }else{
          console.log(this.state.rider)
        const res = await this.props.receiveCollection(this.state.amount,this.state.rider.split('-')[0])
        if(res === 201){
          this.setState({rider: '', amount: ''})
        }
      }
    }
    
    onDriverChange = async (e) => {
        const res = await this.props.hasToPay(e.split('-')[0])
        this.setState({data: res})
        this.setState({rider: e})
    }
  render() { 
    
    const columns = [
      {
        title: 'Driver',
        dataIndex: 'driver',
        key: 'driver',
        width: '15%',
      },
      {
        title: 'Has to Pay',
        dataIndex: 'hasToPay',
        key: 'hasToPay',
        width: '20%'
      },
      {
        title: 'Received',
        dataIndex: 'received',
        key: 'received',
        width: '20%',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '10%'
      }
    ];
    const {data, drivers, rider,amount} = this.state
    const rows = [
      data
        ? this.createData(
            rider.split('-')[2]
            , 
            data?.driver_collection
            ,
              <Input onChange={(e)=> {this.setState({amount: e.target.value})} }/>
            ,
            
            <Button size="small" type="primary" disabled={data?.driver_collection != amount || data?.driver_collection == 0} onClick={() => this.handleSubmit()} >Collect</Button>
            
            )
          
        : "",
    ];
    
    const options = [
      drivers
        ? drivers?.map((item) =>
            this.createOption(
              `${item.mobile_number}----` + item.name,
              `${item.user_id}--`+ item.name
            )
          )
        : null,
    ];
    return (
      <>
        
        <Header title="Collect Amount" subtitle="Parcel" />
        <br />
        <Form.Item name="rider" label="Select a rider">
          <Select
            style={{width: '100%'}}
            size = "large"
            showSearch
            placeholder="Select a rider"
            optionFilterProp="children"
            onChange={this.onDriverChange}
            value={this.state.rider}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options[0]? 
            options[0]?.map((item,id) => (
              <Option value={item?.value}>{item?.label}</Option>
            ))
          :null}
          </Select>
        </Form.Item>
            {rider?.length > 0
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky  />
            : 
            null}
      </>
    );
  }
}

export default connect(null,{fetchDrivers,hasToPay,receiveCollection})(CollectAmount);