import React, { Component } from 'react'

import { Button, Form, Select, Table } from 'antd';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';
import {fetchTransaction} from '../../action/transaction'
import Header from '../../components/header';
const { RangePicker } = DatePicker;
class TransactionList extends Component {
  state = { 
    
    searchText: '',
    searchedColumn: '',
    data: []
   } 
    componentDidMount = async () => {
        
        const res = await this.props.fetchTransaction(null,null)
        this.setState({data: res})
    };
   

    createData = (created_at,transaction_id,from,transfer_amount) => {
        return {created_at,transaction_id,from,transfer_amount};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }


    
    onOpenChange  = async (e) => {
      this.setState({rider: e})
    }

    onDateChange = async (value, dateString) => {
        const res = await this.props.fetchTransaction(dateString[0],dateString[1])
        this.setState({data: res})
    }
  render() { 
    
    const columns = [
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: '15%',
      },
      {
        title: 'Order ID',
        dataIndex: 'transaction_id',
        key: 'transaction_id',
        width: '15%',
      },
      {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
        width: '20%',
      },
      {
        title: 'Transaction',
        dataIndex: 'transfer_amount',
        key: 'transfer_amount',
        width: '20%',
      }
    ];
    const {data} = this.state
    const rows = [...
      data
        ? data?.map((item,id) => this.createData(
            this.makeLocalTime(item?.created_at),
            item.transaction_id
            , 
            item?.driver ? item?.driver?.name + '----' + item?.driver?.mobile_number : null
            ,
              item?.transfer_amount + '(' + item?.warehouse + ')'
            ,
                item?.charges?.amount_to_collect + ' (' + item?.warehouse + ')'
           
            
          ))
        : "",
    ];
    
   
    return (
      <>
        
        <Header title="Transaction List" subtitle="Transaction" />
        <br />
        <Form.Item name="rider" label="Select date range">
        <RangePicker disabledDate={this.disabledDate} onChange={this.onDateChange} style={{width: '100%'}}/>
        </Form.Item>
            {rows?.length > 0
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky  />
            : 
            null}
      </>
    );
  }
}

export default connect(null,{fetchTransaction})(TransactionList);