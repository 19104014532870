
import api from "./api";
import history from "../history";
import {  LOADED, LOADING,ERROR_MESSAGE } from "./type";

// [[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[======On going====]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]
export const fetchOnGoing = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get(`report/on-going/driver?driver=${id}`);
        
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data.report
        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE , payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}

// [[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[======COD====]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]
export const fetchCOD = (from,to,id) => async (dispatch) => {
    const start = from ? from : '';
    const end = to ? to : '';
    const driver = id ? id : '';
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get(`report/cod-collection?startDate=${start}&endDate=${end}&driver=${driver}`);
        
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data.report
        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE , payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}

// [[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[======Assigned====]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]
export const fetchAssigned = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get(`report/assign-order/driver?driver=${id}`);
        
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data
        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE , payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}
