import { Button, Card, Col, Form, Row, Select, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react'
import Header from '../../components/header';
import {fetchDrivers} from '../../action/drivers'
import {fetchAssigned, fetchOnGoing,fetchCOD} from '../../action/report'
import { connect } from 'react-redux';
// import ReactExport from "react-export-excel";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelColumn;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Report = ({fetchDrivers,fetchAssigned,fetchOnGoing,fetchCOD}) => {
    const [riders, setRiders] = useState([])
    const [rider, setRider] = useState([])
    const [assigned, setAssigned] = useState(null)
    const [onGoing, setOnGoing] = useState(null)
    const [collectReport, setCollectReport] = useState(null)
    const [to, setTo] = useState('')
    const [from, setFrom] = useState('')
    
    useEffect( async() => {
      
        const res = await fetchDrivers()
        setRiders(res)
    }, [])
    
    const createOption = (label, value) => {
        return { label, value };
      };
      let options = []
    options = [
    riders
        ? riders?.map((item) =>
            createOption(
            `${item.mobile_number}----` + item.name,
            item.user_id
            )
        )
        : null,
    ];
    const assignedReport = async (e) => {
        const res = await fetchAssigned(e)
       if(res?.report?.length > 0){
        setAssigned(res)
       }
       else{
           
        setAssigned(null)
       }
    }
  
    const onGoingReport = async (e) => {
        setRider(e)
        const res = await fetchOnGoing(e)
        setOnGoing(res)
      }
  
    const collectAmountReport = async (e) => {
        setRider(e)
        const res = await fetchCOD(to,from,e)
        console.log(res)
        setCollectReport(res)
      }

      let onGoingData = [];
      onGoing?.map(item => ( 
        onGoingData.push({'Order Id': item?.order_id, 'Customer': item?.recipient,
          'Merchant': item?.merchant, 'Shop': item?.shop, 'Quantity': item?.quantity,
        "Order Condition": item?.order_condition, "Delivery type": item?.delivery_time,
        "Weight": item?.weight, "Created at": item?.created_at
        })
        
        ))

      let collectData = [];
      collectReport?.map(item => ( 
        collectData.push({'Order Id': item?.order_id, 'Customer': item?.recipient,
          'Merchant': item?.merchant, 'Shop': item?.shop, 'Rider': item?.driver,
        "State": item?.state, "Collected Amount": item?.collected_amount,
        "Created at": item?.created_at
        })
        
        ))
        const name= rider
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const exportToCSV = () => {
            const ws = XLSX.utils.json_to_sheet(onGoingData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, name + fileExtension);
        }
        const exportCollectedToCSV = () => {
            const ws = XLSX.utils.json_to_sheet(collectData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, name + fileExtension);
        }
    
    const makeLocalTime = (date) => {
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
    return date.split('T')[0] + "\n" + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    const pdfGenerate = async (id) => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(10);
        const title = assigned?.driver?.name +" ( "+ assigned?.driver?.user_id +" ) " + " \n" +new Date().toJSON().slice(0,10).replace(/-/g,'/') 
        const headers = [["Order Time","Order ID", "Customer", "Shop", "Amount",   "Status", "Remarks"]];
    
        const data = assigned?.report?.map(elt=> [ makeLocalTime(elt.created_at),elt.order_id, elt.recipient, elt.shop, elt.amount_to_collect, "D | R | H | P" ]);
      
        let content = {
          startY: 80,
          head: headers,
          body: data,
          theme: 'grid'
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`${assigned?.driver?.name} (${assigned?.driver?.user_id}).pdf`)
      }
        

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }
    
    const onDateChange = async (value, dateString) => {
        setTo(dateString[0])
        setFrom(dateString[1])
    }
    
    return ( 
        <>
            <Header title="All Reports" subtitle="Reports" />

            <Card title="Today's Assigned Parcel" bordered={false} style={{ width: '100%' }}>
                <Row>
                    <Col xs={18} >
                        <Form.Item name="rider" style={{width: '100%', backgroundColor: 'red'}} >
                            <Select
                                style={{width: '100%', backgroundColor: 'red'}}
                                size = "large"
                                showSearch
                                placeholder="Select a rider"
                                optionFilterProp="children"
                                onChange={assignedReport}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {options[0]? 
                                options[0]?.map((item,id) => (
                                <Option value={item?.value}>{item?.label}</Option>
                                ))
                            :null}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={2}></Col>
                    <Col xs={4}>
                        {assigned ? 
                            <Button onClick= {()=>pdfGenerate()}>Download</Button>
                        :
                        assigned === null ? <Button disabled>No Data</Button> : null}
                    </Col>
                </Row>
            </Card>
            
            <Card title="Ongoing Parcel" bordered={false} style={{ width: '100%' }}>
                <Row>
                    <Col xs={18}>
                        <Form.Item name="rider" >
                            <Select
                                style={{width: '100%'}}
                                size = "large"
                                showSearch
                                placeholder="Select a rider"
                                optionFilterProp="children"
                                onChange={onGoingReport}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {options[0]? 
                                options[0]?.map((item,id) => (
                                <Option value={item?.value}>{item?.label}</Option>
                                ))
                            :null}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={2}></Col>
                    <Col xs={4}>
                        {onGoing?.length > 0 ? 
                        
                        <span>
                            <Button onClick={(e) => exportToCSV()}>Export</Button>
                        </span>
                        :
                        onGoing !== null ?
                        <span>
                            <Button disabled>No Data</Button>
                        </span>
                        :
                        null
                        }
                    </Col>
                </Row>
            </Card>
            
            <Card title="Collected Amount" bordered={false} style={{ width: '100%' }}>
                <Row>
                    <Col xs={9}>
                        <Form.Item name="rider" label="Select date range">
                            <RangePicker  onChange={onDateChange}/>
                        </Form.Item>
                        </Col>
                        <Col xs={9}>
                            {from ? 
                                <Form.Item name="rider" >
                                    <Select
                                        style={{width: '100%'}}
                                        size = "large"
                                        showSearch
                                        placeholder="Select a rider"
                                        optionFilterProp="children"
                                        onChange={collectAmountReport}
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {options[0]? 
                                        options[0]?.map((item,id) => (
                                        <Option value={item?.value}>{item?.label}</Option>
                                        ))
                                    :null}
                                    </Select>
                                </Form.Item>
                            : null}
                        </Col>
                        
                    <Col xs={2}></Col>
                    <Col xs={4}>
                        {collectReport?.length > 0 ? 
                        
                        <span>
                            <Button onClick={(e) => exportCollectedToCSV()}>Export</Button>
                          
                        </span>
                        :
                        collectReport !== null ?
                        <span>
                            <Button disabled>No Data</Button>
                          
                        </span>
                        :null}
                    </Col>
                </Row>
            </Card>

        </>
     );
}
 
export default connect(null,{fetchDrivers,fetchAssigned,fetchOnGoing,fetchCOD})(Report);