import React from 'react'
import { LockClosedIcon } from '@heroicons/react/solid'
import Logo from './logo.png'
import { connect } from 'react-redux'
import {signin} from '../../action/login'
import Alert from '../../components/alert'
const Login = ({signin,error}) => {
    console.log(error)
    const handleSubmit = (e) => {
        e.preventDefault();
        signin(e?.target?.elements?.user_id?.value,e?.target?.elements?.password?.value)
    }
    return ( 
        <>
        
        {error ? <Alert value={error} /> : null}
        <div className='grid place-items-center h-screen'>
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                <img
                    className="mx-auto h-24 w-auto"
                    src={Logo}
                    alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                </p>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                    <label htmlFor="user_id" className="sr-only">
                        Email address
                    </label>
                    <input
                        id="user_id"
                        name="user_id"
                        type="text"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="User ID"
                    />
                    </div>
                    <div>
                    <label htmlFor="password" className="sr-only">
                        Password
                    </label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                    />
                    </div>
                </div>
    
                
    
                <div>
                    <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                    </span>
                    Sign in
                    </button>
                </div>
                </form>
            </div>
            </div>
        </div>
        </>
     );
}
const mapStateToProps = (state) => {
    return{
      
      loader: state.helpers.loading,
      error: state.helpers.error,
     
    }
  }
export default connect(mapStateToProps, {signin})(Login);