import { PageHeader } from 'antd';
import React from 'react';
import { Link,useLocation } from 'react-router-dom';
const Header = ({title, subtitle, name}) => {
    const location = useLocation();
    let navigation = []
    if(name === 'normal_order'){
        navigation = [
        { name: 'Home', to: '/parcels', current: location.pathname===  '/parcels'   ? true : false },
        { name: 'Peinding', to: '/parcels-pending', current: location.pathname.includes('/parcels-pending')  ? true : false },
        { name: 'Confirmed', to: '/parcels-confirmed', current: location.pathname.includes('/parcels-confirmed')  ? true : false },
        { name: 'Pickup', to: '/parcels-pickup', current: location.pathname === '/parcels-pickup'  ? true : false },
        { name: 'Assign', to: '/parcels-assign', current: location.pathname === '/parcels-assign'  ? true : false },
        { name: 'Out for Delivery', to: '/parcels-out-for-delivery', current: location.pathname === '/parcels-out-for-delivery'  ? true : false },
        { name: 'In Warehouse', to: '/parcels-warehouse', current: location.pathname === '/parcels-warehouse'  ? true : false },
      ]
    }
    if(name === 'return_order'){
        navigation = [
        { name: 'Home', to: '/parcels', current: location.pathname===  '/parcels'   ? true : false },
        { name: 'Collect Return', to: '/parcels-return-pickup', current: location.pathname.includes('/parcels-return-pickup')  ? true : false },
        { name: 'Collect Hold', to: '/parcels-hold-pickup', current: location.pathname.includes('/parcels-hold-pickup')  ? true : false },
        { name: 'Upcoming Return', to: '/parcels-upcoming-return', current: location.pathname === '/parcels-upcoming-return'  ? true : false },
        { name: 'Return from Warehouse', to: '/parcels-warehouse-return', current: location.pathname === '/parcels-warehouse-return'  ? true : false },
      ]
    }
   
    if(name === 'transit_order'){
        navigation = [
        { name: 'Home', to: '/parcels', current: location.pathname===  '/parcels'   ? true : false },
        { name: 'Pending', to: '/parcels-transit-pending', current: location.pathname.includes('/parcels-transit-pending')  ? true : false },
        { name: 'Send', to: '/parcels-send', current: location.pathname.includes('/parcels-send')  ? true : false },
      ]
    }
   
    if(name === 'odd_order'){
        navigation = [
        { name: 'Home', to: '/parcels', current: location.pathname===  '/parcels'   ? true : false },
        { name: 'Pending', to: '/parcels-odd-pending', current: location.pathname.includes('/parcels-odd-pending')  ? true : false },
        { name: 'Assign', to: '/parcels-odd-assign', current: location.pathname.includes('/parcels-odd-assign')  ? true : false },
        { name: 'Processing', to: '/parcels-odd-processing', current: location.pathname.includes('/parcels-odd-processing')  ? true : false },
      ]
    }
   
    
      const classNames = (...classes) =>{
        return classes.filter(Boolean).join(' ')
      }
    return ( 
        <>
        <div className="flex space-x-4 justify-center ">
            {navigation.map((item) => (
            <Link
                key={item.name}
                to={item.to}
                className={classNames(
                item.current ? 'border-b-4 border-red-500 text-red-500' : 'text-black hover:bg-gray-700 hover:text-white',
                'px-3 py-2 text-sm font-medium  '
                )}
                aria-current={item.current ? 'page' : undefined}
            >
                {item.name}
            </Link>
            ))}
        </div>
        <PageHeader
            style={{border: '1px solid rgb(235, 237, 240)'}}
            onBack={() => window.history.back()}
            title={title}
            subTitle={subtitle}
        />
        </>
     );
}
 
export default Header;